import { Drawer, Form, Menu, message } from "antd";
import { useNavigate } from "react-router-dom";
import Sider from "antd/es/layout/Sider";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import { MenuItems, SidebarProps } from "../interface/MainLayout.interface";
import {
  CircleIcon,
  CommunicationIcon,
  HomeIcon,
  NotificationIcon,
  SettingIcon,
  UserIcon,
  CuriculumIcon,
} from "assets";
import logo from "assets/images/Logo.png";
import sloganlogo from "assets/images/sloganlogo.png";
import { tokens } from "utils/Constants";
import { BankNotesIcon } from "assets/icons/BankNotesIcon";
import { getInfo, getUserDeviceResolution } from "utils/Helpers";
import { UserSwitchOutlined, UserDeleteOutlined } from "@ant-design/icons";
import { AButton, AInput, AModal } from "components";
import { useEffect, useState } from "react";
import { JWTToken } from "utils/interface/Utils.interface";
import { useDispatch } from "react-redux";
import { attendanceActions } from "pages/attendance/store";
import { useForm } from "antd/es/form/Form";

interface AMenuItem extends MenuItems {
  type?: "group";
}

type KeyLabelObject = {
  [key: string]: React.ReactNode;
};
var keyLabelObject: KeyLabelObject = {};
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  href?: String,
  children?: AMenuItem[],
  type?: "group"
): AMenuItem {
  keyLabelObject[key] = label;
  return {
    key,
    icon,
    href,
    children,
    label,
    type,
  } as AMenuItem;
}
const items: AMenuItem[] = [
  // getItem("Dashboards", "1", <HomeIcon style={{ width: "12pt" }} />, "/"),

  getItem(
    "Employee",
    "18",
    <UserSwitchOutlined style={{ width: "12pt" }} />,
    "/employeeAttendance",
    [
      getItem(
        "Clockin/Clockout",
        "20",
        <CircleIcon style={{ width: "12pt" }} />,
        "/employeeAttendance"
      ),
      getItem(
        "Attendance",
        "22",
        <CircleIcon style={{ width: "12pt" }} />,
        "/approvebyemployee"
      ),
    ]
  ),

  getItem(
    "Parent",
    "19",
    <UserSwitchOutlined style={{ width: "12pt" }} />,
    "/attendance",
    [
      getItem(
        "Student Checkin/Checkout",
        "21",
        <CircleIcon style={{ width: "12pt" }} />,
        "/attendance"
      ),
    ]
  ),
  getItem(
        "Rewards",
        "28",
        <BankNotesIcon style={{ width: "12pt" }} />,
        "/rewards"
      ),

  // getItem(
  //   "Attendance",
  //   "18",
  //   <UserSwitchOutlined style={{ width: "12pt" }} />,
  //   "/attendance",
  //   [
  //     getItem("Student",
  //       "19",
  //       <CircleIcon style={{ width: "12pt" }} />,
  //       "/attendance"
  //     ),
  //     getItem("Employee",
  //       "20",
  //       <CircleIcon style={{ width: "12pt" }} />,
  //       "/employeeAttendance"
  //     ),
  //   ]
  // ),
  // getItem(
  //   "Communication",
  //   "19",
  //   <CommunicationIcon style={{ width: "12pt" }} />,
  //   "/chat",
  //   [
  //     getItem("Chat", "20", <CircleIcon style={{ width: "12pt" }} />, "/chat"),
  //     getItem(
  //       "News & Events",
  //       "25",
  //       <CircleIcon style={{ width: "12pt" }} />,
  //       "/newsAndEvents"
  //     ),
  //   ]
  // ),
];

export const Sidebar: React.FC<SidebarProps> = (props) => {
  const { open, onClose, onMobile } = props;
  const navigate = useNavigate();
  const [isModalOpen, setIsModelOpen] = useState<boolean>(false);
  const [currentItemKey, setCurrentItemKey] = useState<string | null>(null);
  const [password, setPassword] = useState<string>("");
  const [selectedKey, setSelectedKey] = useState<string>("19");
  const [form] = useForm();

  const [user, setUser] = useState<JWTToken>();
  const dispatch = useDispatch();

  useEffect(() => {
    const userInfo = getInfo();
    setUser(userInfo);
  }, []);

  const findMenuItemByKey = (key: string, items: any): AMenuItem | undefined => {
    for (const item of items) {
      if (item.key === key) {
        return item;
      }
      if (item.children) {
        const found = findMenuItemByKey(key, item.children);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  };
  
  const onClick = ({ key, item }: any) => {
    const label = keyLabelObject[key];
    if (key === "22" || key === "20") {
      setCurrentItemKey(key);
      setIsModelOpen(true);
    } else {
      const foundItem = findMenuItemByKey(key, items);
      if (foundItem && foundItem.href) {
        navigate(foundItem.href.toString(), { state: { label: label } });
      }
    }
    setSelectedKey(key);
    onMobile && onClose(false);
  };
  

  // const onClick = ({ key, item }: any) => {
  //   const label = keyLabelObject[key];
  //   if (key === "1") {
  //     setCurrentItemKey(key);
  //     setIsModelOpen(true);
  //   } else if (key === "20") {
  //     setCurrentItemKey(key);
  //     setIsModelOpen(true);
  //   } else {
  //     navigate(item.props.href, { state: { label: label } });
  //   }
  //   onMobile && onClose(false);
  // };

  const verifyPassword = async () => {
    const data = {
      password: password,
      id: user?.id,
    };
    try {
      await new Promise((resolve, reject) => {
        dispatch(attendanceActions.checkEmployeeAccess(data, resolve, reject));
      });
      setPassword("");
      setIsModelOpen(false);
      if (currentItemKey) {
        const item = findMenuItemByKey(currentItemKey, items);
        if (item && item.href) {
          navigate(item.href.toString());
        }
      }
    } catch (error: any) {
      message.error(error?.response?.data?.errors);
    }
  };

  // const token: string | null = localStorage.getItem("token");
  // const decodedToken: JWTToken = jwt_decode(token || "");

  // const isAuthorized = (permission: string): boolean => {
  //   return true;
  //   // return permissionData(decodedToken).some(element => {
  //   // 	return permission === element;
  //   // });
  // };

  const content = onMobile ? (
    <Drawer
      placement="left"
      open={open}
      closable={true}
      width={getUserDeviceResolution().width - 80}
      onClose={() => onClose(false)}
      style={{
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          padding: "1.5rem 0",
        }}
      >
        <img
          alt="logo"
          src={sloganlogo}
          width={"80px"}
          style={{ 
            marginTop: "-20px",
            marginBottom: "-1rem",
            width: "18rem"
          }}
        />
      </div>
      <Menu
        onClick={onClick}
        mode="inline"
        defaultSelectedKeys={["21"]}
        selectedKeys={[selectedKey]}
        items={items}
        style={{
          overflowX: "hidden",
          width: "max-content !important",
          border: "none",
        }}
      />
       <AModal
        maskClosable={false}
        open={isModalOpen}
        onCancel={() => 
        {
          form.resetFields();
          setSelectedKey("21")
          setPassword("")
          setIsModelOpen(false)}
        }
        title="Enter Password"
        footer={[
          <AButton
            key="cancel"
            onClick={() => {
              form.resetFields();
              setIsModelOpen(false);
              setSelectedKey("21")
              setPassword("")
              navigate("/attendance");
            }}
            style={{ zIndex: 9999 }}
          >
            Cancel
          </AButton>,
          <AButton key="verify" type="primary" onClick={verifyPassword}>
            Verify Password
          </AButton>,
        ]}
      >
        <Form.Item>
          <AInput
            placeholder="Password"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>
      </AModal>
    </Drawer>
    
  ) : (
    <Sider
      trigger={null}
      collapsible
      collapsed={open}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        zIndex: "5",
        left: 0,
        top: 0,
        bottom: 0,
        backgroundColor: tokens.BG_LIGHT,
        borderRight: tokens.COLOR_LIGHTBLACK_L50 + " 1px solid",
        width: "max-content",
      }}
    >
      <div
        style={{
          padding: "1.5rem 0.5rem",
        }}
      >
        <img alt="logo" src={sloganlogo} width={"80px"} 
          style={{
            marginBottom: "-1rem",
            width: "10rem"
          }}
        />
      </div>

      <Menu
        onClick={onClick}
        mode="inline"
        defaultSelectedKeys={["21"]}
        selectedKeys={[selectedKey]}
        items={items}
        style={{
          width: "max-content !important",
        }}
      />

      <AModal
        maskClosable={false}
        open={isModalOpen}
        onCancel={() => 
        {
          form.resetFields();
          setSelectedKey("21")
          setPassword("")
          setIsModelOpen(false)}
        }
        title="Enter Password"
        footer={[
          <AButton
            key="cancel"
            onClick={() => {
              form.resetFields();
              setIsModelOpen(false);
              setSelectedKey("21")
              setPassword("")
              navigate("/attendance");
            }}
            style={{ zIndex: 9999 }}
          >
            Cancel
          </AButton>,
          <AButton key="verify" type="primary" onClick={verifyPassword}>
            Verify Password
          </AButton>,
        ]}
      >
        <Form.Item>
          <AInput
            placeholder="Password"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>
      </AModal>
    </Sider>
  );

  return <>{content}</>;
};

export default Sidebar;
