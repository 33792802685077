import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
    [x: string]: string | number | Array<object> | object;
}

const fetchDisableDates = takeLatest(
    actions.FETCH_DISABLE_DATES,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
        try {
            const response = yield call(
                api.get,
                urlGenerator(`/api/disableDates?branch_id=${action.branch_id}`, action)
            );
            yield put({
                type: actions.FETCH_DISABLE_DATES_SUCCESS,
                payload: response,
            });
            yield call(action.resolve, response);
        } catch (error) {
            yield call(action.reject, error);
        }
    }
);

const createDisableDate = takeLatest(
    actions.CREATE_DISABLE_DATE,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
        try {
            const response = yield call(
                api.post,
                "/api/disableDates",
                action.data,
                null
            );
            action.data.id = response.id;
            yield put({
                type: actions.CREATE_DISABLE_DATE_SUCCESS,
                payload: response.data,
                event: response.event,
            });
            yield call(action.resolve, "success");
        } catch (error) {
            yield call(action.reject, error);
        }
    }
);

const fetchDisableDate = takeLatest(
    actions.FETCH_DISABLE_DATE,
    function* (
        action: AnyAction
    ): Generator<Effect, void, any> {
        try {
            const response = yield call(
                api.get,
                `api/disableDates/${action.id}`,
                false
            );
            yield put({
                type: actions.FETCH_DISABLE_DATE_SUCCESS,
                payload: response,
            });
            yield call(action.resolve, response);
        } catch (error) {
            yield put({ type: actions.FETCH_DISABLE_DATE_FAILED });
            yield call(action.reject, "reject");
        }
    }
);

const updateDisableDate = takeLatest(
    actions.UPDATE_DISABLE_DATE,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
        try {
            const response = yield call(
                api.put,
                `api/disableDates/${action.data.id}`,
                action.data
            );
            yield put({
                type: actions.UPDATE_DISABLE_DATE_SUCCESS,
                payload: response,
            });
            yield call(action.resolve, response);
        } catch (error: any) {
            yield put({ type: actions.UPDATE_DISABLE_DATE_FAILED });
            yield call(action.reject, "reject");
        }
    }
);

const deleteDisableDate = takeLatest(
    actions.DELETE_DISABLE_DATE,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
        try {
            const response = yield call(api.delete, `api/disableDates/${action.id}`);
            yield put({
                type: actions.DELETE_DISABLE_DATE_SUCCESS,
                payload: action.id,
            });
            yield call(action.resolve, response);
        } catch (error: any) {
            yield put({ type: actions.DELETE_DISABLE_DATE_FAILED });
            yield call(action.reject, error.response.data?.errors);
        }
    }
);

export default function* saga() {
    yield all([
        fetchDisableDates,
        createDisableDate,
        fetchDisableDate,
        updateDisableDate,
        deleteDisableDate
    ]);
}