import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import { getUserId } from "utils/Helpers";
import actions from "./actions";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchKidsInfo = takeLatest(
  actions.FETCH_CHILDINFO,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        `api/child/${action.id ? action.id : getUserId()}`
      );
      yield put({
        type: actions.FETCH_CHILDINFO_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);

const fetchChildInfoWithChildId = takeLatest(
  actions.FETCH_CHILDINFO_WITH_CHILDID,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        `api/child/childInfoWithChildId/${action.id ? action.id : getUserId()}`
      );
      yield put({
        type: actions.FETCH_CHILDINFO_WITH_CHILDID_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);

export default function* saga() {
  yield all([fetchKidsInfo, fetchChildInfoWithChildId]);
}
