import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
    [x: string]: string | number | Array<object> | object;
}

const fetchRewards = takeLatest(
    actions.FETCH_REWARDS,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
        try {
            const response = yield call(
                api.get,
                urlGenerator(`/api/rewards/${action?.user_id}`, action)
            );
            yield put({
                type: actions.FETCH_REWARDS_SUCCESS,
                payload: response,
            });
            yield call(action.resolve, response);
        } catch (error) {
            yield call(action.reject, error);
        }
    }
);

const fetchTotalRewardPoints = takeLatest(
    actions.FETCH_TOTAL_REWARD_POINTS,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
        try {
            const response = yield call(
                api.get,
                urlGenerator("/api/totalRewardPoints", action)
            );
            yield put({
                type: actions.FETCH_TOTAL_REWARD_POINTS_SUCCESS,
                payload: response,
            });
            yield call(action.resolve, response);
        } catch (error) {
            yield call(action.reject, error);
        }
    }
);

const saveRewards = takeLatest(
    actions.SAVE_REWARDS,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
        try {
            const response = yield call(
                api.post,
                "/api/rewards",
                action.data,
                null
            );
            action.data.id = response.id;
            yield put({
                type: actions.SAVE_REWARDS_SUCCESS,
                payload: response.data,
                event: response.event,
            });
            yield call(action.resolve, "success");
        } catch (error) {
            yield call(action.reject, error);
        }
    }
);

const addRedeemPrize = takeLatest(
    actions.ADD_REDEEM_PRIZE,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
        try {
            const response = yield call(
                api.post,
                "/api/redeem",
                action.data,
                null
            );
            action.data.id = response.id;
            yield put({
                type: actions.ADD_REDEEM_PRIZE_SUCCESS,
                payload: response.data,
                event: response.event,
            });
            yield call(action.resolve, "success");
        } catch (error) {
            yield call(action.reject, error);
        }
    }
)

export default function* saga() {
    yield all([
        fetchRewards,
        fetchTotalRewardPoints,
        saveRewards,
        addRedeemPrize
    ]);
}