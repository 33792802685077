import { Map } from "immutable";
import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS(
    Map({
        rewards: [],
        totalRewardsPoint: [],
    })
);

export interface Action {
    type: string;
    payload?: any;
    event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actions.FETCH_REWARDS_SUCCESS:
            return state.setIn(["rewards"], action.payload);

        case actions.FETCH_TOTAL_REWARD_POINTS_SUCCESS:
            return state.setIn(["totalRewardsPoint"], action.payload);

        default:
            return state;
    }
};

export default reducer;

