const actions = {
    FETCH_PRIZES: "FETCH_PRIZES",
    FETCH_PRIZES_SUCCESS: "FETCH_PRIZES_SUCCESS",
    FETCH_PRIZES_FAILED: "FETCH_PRIZES_FAILED",
    CREATE_PRIZE: "CREATE_PRIZE",
    CREATE_PRIZE_SUCCESS: "CREATE_PRIZE_SUCCESS",
    CREATE_PRIZE_FAILED: "CREATE_PRIZE_FAILED",
    UPDATE_PRIZE: "UPDATE_PRIZE",
    UPDATE_PRIZE_SUCCESS: "UPDATE_PRIZE_SUCCESS",
    UPDATE_PRIZE_FAILED: "UPDATE_PRIZE_FAILED",
    DELETE_PRIZE: "DELETE_PRIZE",
    DELETE_PRIZE_SUCCESS: "DELETE_PRIZE_SUCCESS",
    DELETE_PRIZE_FAILED: "DELETE_PRIZE_FAILED",
    FETCH_PRIZE: "FETCH_PRIZE",
    FETCH_PRIZE_SUCCESS: "FETCH_PRIZE_SUCCESS",
    FETCH_PRIZE_FAILED: "FETCH_PRIZE_FAILED",
    FETCH_PRIZE_REDEEMED_BY: "FETCH_PRIZE_REDEEMED_BY",
    FETCH_PRIZE_REDEEMED_BY_SUCCESS: "FETCH_PRIZE_REDEEMED_BY_SUCCESS",
    FETCH_PRIZE_REDEEMED_BY_FAILED: "FETCH_PRIZE_REDEEMED_BY_FAILED",
  
    createPrize: (
      data: Object,
      resolve: (data: string) => void,
      reject: (value: string) => void
    ) => ({
      type: actions.CREATE_PRIZE,
      data,
      resolve,
      reject,
    }),
  
    updatePrize: (
      data: Object,
      resolve: (data: string) => void,
      reject: (value: string) => void
    ) => ({
      type: actions.UPDATE_PRIZE,
      data,
      resolve,
      reject,
    }),
  
    fetchPrizes: (
      page: number,
      pageSize: number,
      resolve: (data: string) => void,
      reject: (value: string) => void
    ) => ({
      type: actions.FETCH_PRIZES,
      page,
      pageSize,
      resolve,
      reject,
    }),
  
    fetchPrize: (
      // page: number,
      // pageSize: number,
      id: number,
      resolve: (data: string) => void,
      reject: (value: string) => void
    ) => ({
      type: actions.FETCH_PRIZE,
      // page,
      // pageSize,
      id,
      resolve,
      reject,
    }),
  
    deletePrize: (
      id: number,
      resolve: (data: string) => void,
      reject: (value: string) => void
    ) => ({
      type: actions.DELETE_PRIZE,
      id,
      resolve,
      reject,
    }),

    redeemedBy: (
      page: number,
      pageSize: number,
      resolve: (data: string) => void,
      reject: (value: string) => void
    ) => ({
      type: actions.FETCH_PRIZE_REDEEMED_BY,
      page,
      pageSize,
      resolve,
      reject
    })
  };
  
  export default actions;
  