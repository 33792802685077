import RewardsSaga from "./sagas";
import RewardsReducer from "./reducers";
import rewardsActions from "./actions";

export const rewardsReducer = {
    rewardsReducer: RewardsReducer,
};

export const rewardsSaga = [RewardsSaga()];

export { rewardsActions };