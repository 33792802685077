const actions = {
    CREATE_DISABLE_DATE: "CREATE_DISABLE_DATE",
    CREATE_DISABLE_DATE_SUCCESS: "CREATE_DISABLE_DATE_SUCCESS",
    CREATE_DISABLE_DATE_FAILED: "CREATE_DISABLE_DATE_FAILED",
    FETCH_DISABLE_DATES: "FETCH_DISABLE_DATES",
    FETCH_DISABLE_DATES_SUCCESS: "FETCH_DISABLE_DATES_SUCCESS",
    FETCH_DISABLE_DATES_FAILED: "FETCH_DISABLE_DATES_FAILED",
    FETCH_DISABLE_DATE: "FETCH_DISABLE_DATE",
    FETCH_DISABLE_DATE_SUCCESS: "FETCH_DISABLE_DATE_SUCCESS",
    FETCH_DISABLE_DATE_FAILED: "FETCH_DISABLE_DATE_FAILED",
    UPDATE_DISABLE_DATE: "UPDATE_DISABLE_DATE",
    UPDATE_DISABLE_DATE_SUCCESS: "UPDATE_DISABLE_DATE_SUCCESS",
    UPDATE_DISABLE_DATE_FAILED: "UPDATE_DISABLE_DATE_FAILED",
    DELETE_DISABLE_DATE: "DELETE_DISABLE_DATE",
    DELETE_DISABLE_DATE_SUCCESS: "DELETE_DISABLE_DATE_SUCCESS",
    DELETE_DISABLE_DATE_FAILED: "DELETE_DISABLE_DATE_FAILED",

    createDisableDate: (
        data: any,
        resolve: (value: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.CREATE_DISABLE_DATE,
        data,
        resolve,
        reject,
    }),

    fetchDisableDates: (
        page: number,
        pageSize: number,
        resolve: (token: string) => void,
        reject: (value: string) => void,
        branch_id: any,
    ) => ({
        type: actions.FETCH_DISABLE_DATES,
        page,
        pageSize,
        resolve,
        reject,
        branch_id,
    }),

    fetchDisableDate: (
        // page: number,
        // pageSize: number,
        id: number,
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.FETCH_DISABLE_DATE,
        // page,
        // pageSize,
        id,
        resolve,
        reject,
    }),

    updateDisableDate: (
        data: Object,
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.UPDATE_DISABLE_DATE,
        data,
        resolve,
        reject,
    }),

    deleteDisableDate: (
        id: number,
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.DELETE_DISABLE_DATE,
        id,
        resolve,
        reject,
    }),
}

export default actions;