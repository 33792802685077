import { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { MainLayoutProps } from "../interface/MainLayout.interface";
import {
  Avatar,
  Badge,
  Breadcrumb,
  Col,
  Dropdown,
  Layout,
  MenuProps,
  Row,
  Space,
  theme,
} from "antd";
import { Header, Content } from "antd/es/layout/layout";
import {
  UserOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HeartOutlined,
} from "@ant-design/icons";
import actions from "pages/login/store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NotificationIcon } from "assets/icons/NotificationIcon";
import { AButton, ATitle } from "components";
import { IRootState } from "store/interface/Store.interface";
import { notificationActions } from "pages/notifications/store";
import { NotificationProps } from "pages/notifications";
import { tokens } from "utils/Constants";
import {
  getFormattedBranchAddress,
  getFormattedFullName,
  getFormattedRoleName,
  getInfo,
  isOnMobile,
} from "utils/Helpers";
import Footer from "components/Footer";
import { ChatIcon } from "assets";
import chatActions from "../store/actions";
import { JWTToken } from "utils/interface/Utils.interface";
const BranchAdminLayout = ({ children }: MainLayoutProps): JSX.Element => {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(isOnMobile());
  const [onMobile, setOnMobile] = useState<boolean>(isOnMobile());
  const [user, setUser] = useState<JWTToken>();

  useEffect(() => {
    const userInfo = getInfo();
    setUser(userInfo);
  }, []);

  const checkOnMobile = () => {
    setOnMobile(isOnMobile());
    if (!isOnMobile()) {
      setSidebarOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", checkOnMobile);

    return () => {
      window.addEventListener("resize", checkOnMobile);
    }
  }, []);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formattedFullName = getFormattedFullName();
  const formattedRoleName = getFormattedRoleName();
  const formattedBranchAddress = getFormattedBranchAddress();
  const notifications = useSelector((state: IRootState) =>
    state.notificationReducer.get("notifications")
  );

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(notificationActions.fetchNotifications(resolve, reject));
    });
  }, []);
  const items: MenuProps["items"] = [
    {
      label: "Settings",
      key: "0",
      onClick: () => {
        navigate("settings");
      },
    },
    {
      type: "divider",
    },
    {
      label: "Logout",
      key: "1",
      onClick: () => {
        new Promise((resolve, reject) => {
          navigate("/login");
          dispatch(actions.logout(resolve, reject));
        });
      },
    },
  ];

  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const lastPart = pathSnippets[pathSnippets.length - 1];
  if (!isNaN(Number(lastPart))) {
    pathSnippets.pop();
  }
  const breadcrumbNameMap: Record<string, string> = {
    "/evaluation-test-requests": "Enrollment Requests",
    "/enrollment": "Enrollment",
    "/enrolledStudents": "Enrolled Students",
    "/enrolledStudents/details": "Student Details",
    "/course": "Full Time Course",
    "/course/add": "Add Course",
    "/course/edit": "Edit Course",
    "/course/details": "Course Details",
    "/branchAdmin": "Branch Admin",
    "/instructor": "Instructor",
    "/instructor/add": "Add Instructor",
    "/instructor/edit": "Edit Instructor",
    "/instructor/details": "Instructor Details",
    "/branchAssociate": "Branch Associate",
    "/branchAssociate/add": "Add Branch Associate",
    "/branchAssociate/edit": "Edit Branch Associate",
    "/branchAssociate/details": "Branch Associate Details",
    "/branch": "Branch",
    "/branch/add": "Add Branch",
    "/branch/edit": "Edit Branch",
    "/batch": "Batch",
    "/batch/add": "Add Batch",
    "/batch/edit": "Edit Batch",
    "/batch/details": "Batch Details",
    "/timeSlot": "Time Slot",
    "/learning-materials": "Learning Materials",
    "/learning-materials/upload": "Upload",
    "/newsAndEvents": "News and Events",
    "/newsAndEvents/eventsDetails": "Event Details",
    "/newsAndEvents/viewAllNews": "News",
    "/newsAndEvents/viewAllEvents": "Events",
    "/bilingandpayments": "Billing & Payments",
    "/branchSelfService": "Branch Self Service",
    "/branchSelfService/add": "Add Branch Self Service",
    "/branchSelfService/edit": "Edit Branch Self Service",
    "/branchSelfService/details": "Branch Self Service Details",
    "/survey": "Survey",
    "/trialClassRequest": "Trial Class Request",
    "/trialClassRequest/details": "Trial Class Request Details",
    "/appointmentRequest": "Appointment Request",
    "/appointmentRequest/details": "Appointment Request Details",
    "/waitinglist": "Waiting List",
    "/timesheetreport/employee": "Employee Timesheet Report",
    "/timesheetreport/employee/detail": "Employee Timesheet Detail",
    "/timesheetreport/self": "Self Timesheet Report",
    "/part-time-course": "Part Time Course",
    "/referrals": "Referrals",
    "/branchSelfService/changePassword": "Change Password",
    "/achievement": "Achievements",
    "/achievement/create": "Add Achievement",
    "/achievement/edit": "Edit Achievment",
    "/rewards": "Rewards",
    "/rewards/add": "Create Reward",
    "/rewards/details": "Reward Details",
    "/redeem": "Redeem",
    "/prizes": "Prizes",
    "/redeemedby": "Redeemed By",
    "/prizes/add": "Add New Prize",
    "/prizes/edit": "Edit Prize",
    "/disabledate": "Disable Date",
    "/disabledate/create": "Disable Specific Date",
    "/disabledate/edit": "Edit Disable Date",
  };

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return {
      key: url,
      title: <Link to={url}>{breadcrumbNameMap[url]}</Link>,
    };
  });
  const breadcrumbItems = extraBreadcrumbItems;
  const titleFromPath = breadcrumbNameMap[`/${pathSnippets.join("/")}`] || "";

  const chatBadge = useSelector((state: IRootState) =>
    state.badgeReducer.get("chatBadge")
  );
  const notificationBadge = useSelector((state: IRootState) =>
    state.badgeReducer.get("notificationBadge")
  );
  return (
    <Layout hasSider={true}>
      <Sidebar
        open={isSidebarOpen}
        onClose={setSidebarOpen}
        onMobile={onMobile}
      />
      <Layout>
        <Content
          style={{
            transition: "ease-in-out",
            transitionDuration: "0.2s",
            minHeight: "100vh",
          }}
        >
          <Header id="header">
            {isOnMobile() && (
              <AButton
                type="text"
                icon={
                  isSidebarOpen ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                }
                onClick={() => setSidebarOpen(!isSidebarOpen)}
                style={{
                  width: "3rem",
                  height: "3rem",
                  color: tokens.COLOR_PRIMARY,
                }}
              />
            )}
            <Space style={{ justifyContent: "flex-end", width: "100%" }}>
              <Badge
                count={
                  notifications.filter(
                    (notification: NotificationProps) =>
                      (notification?.model_type === "Group Chat" ||
                        notification?.model_type === "Chat") &&
                      notification.seen === 0
                  ).length + chatBadge
                }
              >
                <Avatar
                  className="notification"
                  shape="circle"
                  size={32}
                  icon={<ChatIcon />}
                  onClick={() => {
                    dispatch(chatActions.makeChatBadgeNull());
                    navigate("/chat");
                  }}
                />
              </Badge>

              <Badge
                count={
                  notifications.filter(
                    (notification: NotificationProps) =>
                      notification?.model_type !== "Group Chat" &&
                      notification?.model_type !== "Chat" &&
                      notification.seen === 0
                  ).length + notificationBadge
                }
              >
                <Avatar
                  className="notification"
                  shape="circle"
                  size={32}
                  icon={<NotificationIcon />}
                  onClick={() => {
                    navigate("notifications");
                  }}
                />
              </Badge>
              <Dropdown menu={{ items }}>
                <a href=" " onClick={(e) => e.preventDefault()}>
                  <Space>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        lineHeight: "0px",
                      }}
                    >
                      <p
                        style={{
                          textTransform: "capitalize",
                          color: tokens.COLOR_USERNAME,
                          fontSize: "1rem",
                          lineHeight: "0",
                          marginBottom: "0.3rem",
                          marginLeft: "1rem",
                        }}
                      >
                        {formattedFullName}
                      </p>
                      <p
                        style={{
                          textTransform: "capitalize",
                          color: tokens.COLOR_NEUTRAL_N80,
                          fontSize: "0.8rem",
                          marginBottom: "0.3rem",
                          lineHeight: "0",
                        }}
                      >
                        {user?.UserDetail?.unique_user_id}
                      </p>
                      <p
                        style={{
                          textTransform: "capitalize",
                          color: tokens.COLOR_NEUTRAL_N80,
                          fontSize: "0.8rem",
                          lineHeight: "0",
                        }}
                      >
                        {formattedRoleName}, {formattedBranchAddress ? formattedBranchAddress : "Corporate"}
                      </p>
                    </div>
                    <Avatar shape="circle" size={32} icon={<UserOutlined />} />
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </Space>
          </Header>
          <div
            style={{
              paddingLeft: "16px",
            }}
          >
            <ATitle
              level={3}
              content={titleFromPath}
              style={{ color: tokens.COLOR_NEUTRAL_N500 }}
            />
          </div>
          <div
            style={{
              paddingLeft: "16px",
            }}
          >
            <Breadcrumb separator=">" items={breadcrumbItems} />
          </div>

          <Content
            style={{
              margin: "0 24px",
              // padding: 24,
              // background: colorBgContainer,
            }}
          >
            {children}
          </Content>
        </Content>
      </Layout>
      <Footer />
    </Layout>
  );
};

export default BranchAdminLayout;
