import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchPrices = takeLatest(
  actions.FETCH_PRICES,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        urlGenerator("api/priceandplans", action)
      );
      yield put({
        type: actions.FETCH_PRICES_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

// const updatePrice = takeLatest(
//   actions.UPDATE_PRICES,
//   function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
//     try {
//       const response = yield call(
//         api.put,
//         `api/priceandplans/${action.data.id}`,
//         action.data
//       );
//       yield put({
//         type: actions.UPDATE_PRICES_SUCCESS,
//         payload: response,
//       });
//       yield call(action.resolve, response);
//     } catch (error: any) {
//       yield put({ type: actions.UPDATE_PRICES_FAILED });
//       yield call(action.reject, "reject");
//     }
//   }
// );

const fetchPrice = takeLatest(
  actions.FETCH_PRICE,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        urlGenerator(`api/priceandplans/${action.id}`, false)
      );
      yield put({
        type: actions.FETCH_PRICE_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const savePrice = takeLatest(
  actions.SAVE_PRICES,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      if (action.event === "ADD") {
        const response = yield call(
          api.post,
          "api/priceandplans",
          action.data,
          null
        );
        action.data.id = response.id;
        yield put({
          type: actions.SAVE_PRICES_SUCCESS,
          payload: response,
          event: action.event,
        });
        yield call(action.resolve, "success");
      }

      if (action.event === "EDIT") {
        yield call(api.put, `api/priceandplans/${action.data.id}`, action.data);
        yield put({
          type: actions.SAVE_PRICES_SUCCESS,
          payload: action.data,
          event: action.event,
        });
        yield call(action.resolve, "success");
      }
    } catch (error) {
      console.error("Error saving price:", error);
      yield call(action.reject, "failed"); // Reject on error
    }
  }
);

const deletePrice = takeLatest(
  actions.DELETE_PRICES,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.delete, `api/priceandplans/${action.id}`);
      yield put({
        type: actions.DELETE_PRICES_SUCCESS,
        payload: action.id,
      });
      yield call(action.resolve, response);
    } catch (error: any) {
      yield put({ type: actions.DELETE_PRICES_FAILED });
      yield call(action.reject, error.response.data?.errors);
    }
  }
);

export default function* saga() {
  yield all([savePrice, fetchPrices, fetchPrice, deletePrice]);
}
