import { Select, SelectProps } from "antd";
import React from "react";

interface ASelectProps extends SelectProps {
  customized: boolean;
  multiple?: boolean;
  option?: string[];
}

function ASelect({ customized, multiple, option, children, ...others }: ASelectProps) {
  if (customized) {
    const transformedOptions = option?.map(option => ({ label: option, value: option }));

    return (
      <Select
      showSearch
      filterOption={(input, option) =>
        typeof option?.label === "string" &&
        option.label.toLowerCase().includes(input.toLowerCase())
      }
        mode={multiple ? "multiple": undefined}
        bordered={false}
        style={{
          borderBottom: "1px solid black",
          fontSize: "14px",
        }}
        options={transformedOptions}
        {...others}
      >
        {children}
      </Select>
    );
  } else {
    return (
      <Select 
      bordered={true}
      showSearch
  filterOption={(input, option) => {
    const children: any = option?.children;
    if (typeof children === "string") {
      return children.toLowerCase().includes(input.toLowerCase());
    }
    return false;
  }}
      {...others}>
        {children}
      </Select>
    );
  }
}

export default ASelect;
