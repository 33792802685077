import PrizeReducer from "./reducers";
import PrizeSaga from "./sagas";
import prizeActions from "./actions";

export const prizeReducer = {
  prizeReducer: PrizeReducer,
};

export const prizeSaga = [PrizeSaga()];

export { prizeActions };
