import jwt_decode from "jwt-decode";
import { JWTToken } from "utils/interface/Utils.interface";
import MainLayout from "./mainLayout";
import { Route, Routes, useNavigate } from "react-router-dom";
import { restrictedMainRoutes, PermittedRoutes } from "routes";
import { RestrictedRoutes } from "routes/index.interface";
import BranchAdminLayout from "./branchAdminLayout";
import BranchAssociateLayout from "./branchAssociateLayout";
import InstructorLayout from "./instructorLayout";
import ParentLayout from "./parentLayout";
import ChildLayout from "./childLayout";
import NotFound from "pages/notFound";
import {
  getUserId,
  hasTokenExpired,
  isAdmin,
  isBranchAdmin,
  isBranchAssociate,
  isBranchSelfService,
  isChild,
  isCustomerSupportAdmin,
  isCustomerSupportUser,
  isInstructor,
  isParent,
} from "utils/Helpers";
import { notification, Space, message } from "antd";
import { io, Socket } from "socket.io-client";
import { roomActivities } from "utils/Constants";
import { createContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import actions from "./store/actions";
import { NotificationPlacement } from "antd/es/notification/interface";
import { openNotification } from "components/Notification";
import { NotificationProps } from "pages/notifications";
import BranchSelfServiceLayout from "./branchSelfServiceLayout";
import CustomerSupportAdminLayout from "./customerSupportAdmin";
import CustomerSupportUserLayout from "./customerSupportUser";

const ConditionalLayout: React.FC = () => {
  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userID = getUserId();
  const URL = process.env.REACT_APP_API_BASE_URL || "";
  const socket: Socket = io(URL, {
    query: { userId: userID.toString() },
  });

  useEffect(() => {
    socket.emit(roomActivities.JOIN_ROOM, userID.toString());

    return () => {
      socket.emit(roomActivities.LEAVE_ROOM, userID.toString());
  };
  }, []);

  const handleReceivedNotification = (data: NotificationProps) => {
    openNotification("bottomRight", data, () => handleClick(data));
    if (data?.model_type === "Group Chat " || data?.model_type === "Chat") {
      dispatch(actions.increaseChatBadge());
    } else {
      dispatch(actions.increaseNotificationBadge());
    }
  };

  const handleClick = (data: NotificationProps) => {
    notification.destroy();
    if (data?.model_type === "Chat" || data.model_type === "Group Chat") {
      navigate("/chat", { state: { data: data } });
    } else {
      navigate("/notifications");
    }
    if (data?.model_type === "Group Chat " || data?.model_type === "Chat") {
      dispatch(actions.decreasChateBadge());
    } else {
      dispatch(actions.decreaseNotificatioBadge());
    }
  };

  socket.on("notification", handleReceivedNotification);

  const route: any = (
    <Routes>
      {restrictedMainRoutes.map(
        ({ path, permission, Component }: RestrictedRoutes) => {
          return (
            <Route
              key={path}
              path={path}
              element={
                <PermittedRoutes
                  permission={permission}
                  Component={Component}
                />
              }
            />
          );
        }
      )}
    </Routes>
  );

  if (hasTokenExpired()) {
    message.error("Token expired please login !");
    navigate("/login");
  }

  const getLayoutBasedOnRole = () => {
    if (isAdmin()) return MainLayout;
    if (isInstructor()) return InstructorLayout;
    if (isBranchAdmin()) return BranchAdminLayout;
    if (isParent()) return ParentLayout;
    if (isChild()) return ChildLayout;
    if (isBranchAssociate()) return BranchAssociateLayout;
    if (isBranchSelfService()) return BranchSelfServiceLayout;
    if (isCustomerSupportAdmin()) return CustomerSupportAdminLayout;
    if(isCustomerSupportUser()) return CustomerSupportUserLayout;
    return NotFound;
  };

  const LayoutComponent = getLayoutBasedOnRole();

  return <LayoutComponent>{route}</LayoutComponent>;
};
export default ConditionalLayout;
