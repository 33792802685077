const actions = {
  SAVE_PRICES: "SAVE_PRICES",
  SAVE_PRICES_SUCCESS: "SAVE_PRICES_SUCCESS",
  SAVE_PRICES_FAILED: "SAVE_PRICES_FAILED",
  FETCH_PRICES: "FETCH_PRICES",
  FETCH_PRICES_SUCCESS: "FETCH_PRICES_SUCCESS",
  FETCH_PRICES_FAILED: "FETCH_PRICES_FAILED",
  FETCH_PRICE: "FETCH_PRICE",
  FETCH_PRICE_SUCCESS: "FETCH_PRICE_SUCCESS",
  FETCH_PRICE_FAILED: "FETCH_PRICE_FAILED",
  UPDATE_PRICES: "UPDATE_PRICES",
  UPDATE_PRICES_SUCCESS: "UPDATE_PRICES_SUCCESS",
  UPDATE_PRICES_FAILED: "UPDATE_PRICES_FAILED",
  DELETE_PRICES: "DELETE_PRICES",
  DELETE_PRICES_SUCCESS: "DELETE_PRICES_SUCCESS",
  DELETE_PRICES_FAILED: "DELETE_PRICES_FAILED",

  savePrice: (
    data: any,
    event: string,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.SAVE_PRICES,
    data,
    event,
    resolve,
    reject,
  }),

  // updatePrice: (
  //   id: string,
  //   data: any,
  //   event: string,
  //   resolve: (value: string) => void,
  //   reject: (value: string) => void
  // ) => ({
  //   id,
  //   type: actions.UPDATE_PRICES,
  //   data,
  //   event,
  //   resolve,
  //   reject,
  // }),

  fetchPrices: (
    resolve: (token: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_PRICES,
    resolve,
    reject,
  }),

  deletePrice: (
    id: string,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.DELETE_PRICES,
    id,
    resolve,
    reject,
  }),

  fetchPrice: (
    id: number,
    resolve: (token: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_PRICE,
    id,
    resolve,
    reject,
  }),
};

export default actions;
