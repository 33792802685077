import { Map } from "immutable";
import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS(
    Map({
        disableDates: [],
    })
);

export interface Action {
    type: string;
    payload?: any;
    event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actions.FETCH_DISABLE_DATES:
            return state.setIn(["loading"], true);

        case actions.FETCH_DISABLE_DATES_SUCCESS:
            return state.merge({
                loading: false,
                disableDates: action.payload,
            });

        case actions.FETCH_DISABLE_DATE_SUCCESS:
            return state.merge({
                loading: false,
                activeDisableDate: action.payload,
            });

        case (actions.FETCH_DISABLE_DATES_FAILED, actions.CREATE_DISABLE_DATE_FAILED, actions.UPDATE_DISABLE_DATE_FAILED):
            return state.setIn(["loading"], false);

        case actions.UPDATE_DISABLE_DATE_SUCCESS:
            const updatedEntry = action.payload;
            const disableDateIndex = state
                .toJS()
                .disableDates?.data?.findIndex(
                    (disabledate: any) => disabledate.id === action.payload.id
                );
            return state.setIn(
                ["disableDates", "data", disableDateIndex],
                updatedEntry
            );

        case actions.DELETE_DISABLE_DATE_SUCCESS:
            const delDisableDateIndex = state
                .toJS()
                .disableDates?.data?.findIndex(
                    (disabledate: any) => disabledate.id === action.payload
                );

            return state.updateIn(["disableDates", "data"], (disableDatesList: any) =>
                disableDatesList.filter((disableDate: any) => disableDate.id !== action.payload)
            );

        default:
            return state;
    }
};

export default reducer;

