import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
const { fromJS, Map } = require("immutable");

const initialState = fromJS(
    Map({
        loading: false,
        prizes: [],
        prizeRedeemedBy: [],
    })
);

export interface Action {
    type: string;
    payload?: any;
    event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actions.CREATE_PRIZE_SUCCESS:
            const savePrizeIndex = state.toJS().prizes.length;
            return state.setIn(
                ["prizes", savePrizeIndex],
                action.payload
            );

        case actions.FETCH_PRIZE_SUCCESS:
            return state.merge({
                loading: false,
                activePrize: action.payload,
            });

        case actions.FETCH_PRIZES:
            return state.setIn(["loading"], true);

        case actions.FETCH_PRIZES_SUCCESS:
            return state.merge({
                loading: false,
                prizes: action.payload,
            });

        case (actions.FETCH_PRIZES_FAILED, actions.CREATE_PRIZE_FAILED, actions.FETCH_PRIZE_REDEEMED_BY_FAILED):
            return state.setIn(["loading"], false);

        case actions.UPDATE_PRIZE_SUCCESS:
            const updatedEntry = action.payload;
            const prizeIndex = state
                .toJS()
                .prizes?.data?.findIndex(
                    (prizes: any) => prizes.id === action.payload.id
                );
            return state.setIn(
                ["prizes", "data", prizeIndex],
                updatedEntry
            );

        case actions.DELETE_PRIZE_SUCCESS: 
            const prizesArray = state.getIn(["prizes", "data"]);
            const delPrizeIndex = prizesArray.findIndex(
                (prize: any) => prize.id === action.payload
            );

            if (delPrizeIndex === -1) {
                return state;
            }

            return state.deleteIn(["prizes", "data", delPrizeIndex]);

        case actions.FETCH_PRIZE_REDEEMED_BY:
            return state.setIn(["loading"], true);

        case actions.FETCH_PRIZE_REDEEMED_BY_SUCCESS:
            return state.merge({
                loading: false,
                prizeRedeemedBy: action.payload,
            });

        default:
            return state;
    }
};

export default reducer;