import { Map } from "immutable";
import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS(
  Map({
    priceAndPlans: [],
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.SAVE_PRICES_SUCCESS:
      return state.setIn(["priceAndPlans"], action.payload);

    // case actions.UPDATE_PRICES_SUCCESS:
    //   const updatedEntry = action.payload;
    //   const priceIndex = state
    //     .toJS()
    //     .priceAndPlans?.findIndex(
    //       (price: any) => price.id === action.payload.id
    //     );
    //   return state.setIn(["priceAndPlans", "data", priceIndex], updatedEntry);

    case actions.FETCH_PRICES_SUCCESS:
      return state.setIn(["priceAndPlans"], action.payload);

    case actions.FETCH_PRICE_SUCCESS:
      return state.merge({
        loading: false,
        activePrice: action.payload,
      });

    case actions.DELETE_PRICES_SUCCESS:
      const deletePriceIndex = state
        .toJS()
        .priceAndPlans?.rows?.findIndex(
          (price: any) => price.id === Number(action.payload)
        );

      if (deletePriceIndex === -1 || deletePriceIndex === undefined) {
        return state;
      }

      // Use `deleteIn` with the correct path.
      return state.deleteIn(["priceAndPlans", "rows", deletePriceIndex]);

    default:
      return state;
  }
};

export default reducer;
