import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
const { fromJS, Map } = require("immutable");

const initialState = fromJS(
  Map({
    loading: false,
    achievements: [],
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.CREATE_ACHIEVEMENT_SUCCESS:
      const saveAchievementIndex = state.toJS().achievements.length;
      return state.setIn(
        ["achievements", saveAchievementIndex],
        action.payload
      );

    case actions.FETCH_ACHIEVEMENT_SUCCESS:
      return state.merge({
        loading: false,
        activeAchievement: action.payload,
      });

    case actions.FETCH_ACHIEVEMENTS:
      return state.setIn(["loading"], true);

    case actions.FETCH_ACHIEVEMENTS_SUCCESS:
      return state.merge({
        loading: false,
        achievements: action.payload,
      });

    case (actions.FETCH_ACHIEVEMENTS_FAILED, actions.CREATE_ACHIEVEMENT_FAILED):
      return state.setIn(["loading"], false);

    case actions.UPDATE_ACHIEVEMENT_SUCCESS:
      const updatedEntry = action.payload;
      const achievementIndex = state
        .toJS()
        .achievements?.findIndex(
          (achievements: any) => achievements.id === action.payload.id
        );
      return state.setIn(
        ["achievements", "data", achievementIndex],
        updatedEntry
      );

    case actions.DELETE_ACHIEVEMENT_SUCCESS:
      const delAchievementIndex = state
        .toJS()
        .achievements?.findIndex(
          (achievement: any) => achievement.id === action.payload
        );

      if (delAchievementIndex === -1 || delAchievementIndex === undefined) {
        return state;
      }

      // Use `deleteIn` with the correct path.
      return state.deleteIn(["achievements", delAchievementIndex]);

    default:
      return state;
  }
};

export default reducer;
