const actions = {
    FETCH_REWARDS: "FETCH_REWARDS",
    FETCH_REWARDS_SUCCESS: "FETCH_REWARDS_SUCCESS",
    FETCH_REWARDS_FAILED: "FETCH_REWARDS_FAILED",
    SAVE_REWARDS: "SAVE_REWARDS",
    SAVE_REWARDS_SUCCESS: "SAVE_REWARDS_SUCCESS",
    FETCH_TOTAL_REWARD_POINTS: "FETCH_TOTAL_REWARD_POINTS",
    FETCH_TOTAL_REWARD_POINTS_SUCCESS: "FETCH_TOTAL_REWARD_POINTS_SUCCESS",
    FETCH_TOTAL_REWARD_POINTS_FAILED: "FETCH_TOTAL_REWARD_POINTS_FAILED",
    ADD_REDEEM_PRIZE: "ADD_REDEEM_PRIZE",
    ADD_REDEEM_PRIZE_SUCCESS: "ADD_REDEEM_PRIZE_SUCCESS",
    ADD_REDEEM_PRIZE_FAILED: "ADD_REDEEM_PRIZE_FAILED",

    fetchRewards: (
        user_id: any,
        page: number,
        pageSize: number,
        resolve: (token: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.FETCH_REWARDS,
        user_id,
        page,
        pageSize,
        resolve,
        reject
    }),

    saveRewards: (
        data: any,
        resolve: (value: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.SAVE_REWARDS,
        data,
        resolve,
        reject,
    }),

    fetchTotalRewardPoints: (
        branchId: any,
        page: number,
        pageSize: number,
        resolve: (token: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.FETCH_TOTAL_REWARD_POINTS,
        branchId,
        page,
        pageSize,
        resolve,
        reject
    }),

    addRedeemPrize: (
        data: any,
        resolve: (token: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.ADD_REDEEM_PRIZE,
        data,
        resolve,
        reject
    })
}

export default actions;