const actions = {
  FETCH_CHILDINFO: "FETCH_CHILDINFO",
  FETCH_CHILDINFO_SUCCESS: "FETCH_CHILDINFO_SUCCESS",
  FETCH_CHILDINFO_WITH_CHILDID: "FETCH_CHILDINFO_WITH_CHILDID",
  FETCH_CHILDINFO_WITH_CHILDID_SUCCESS: "FETCH_CHILDINFO_WITH_CHILDINFO_WITH_CHILDID_SUCCESS",

  fetchChildInfo: (
    resolve: (token: string) => void,
    reject: (value: string) => void,
    id?: any
  ) => ({
    id,
    type: actions.FETCH_CHILDINFO,
    resolve,
    reject,
  }),

  fetchChildInfoWithChildId: (
    resolve: (token: string) => void,
    reject: (value: string) => void,
    id?: any
  ) => ({
    id,
    type: actions.FETCH_CHILDINFO_WITH_CHILDID,
    resolve,
    reject,
  }),
};

export default actions;
