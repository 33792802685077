import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

interface PrizeReturnType {
  [x: string]: string;
}

const createPrize = takeLatest(
  actions.CREATE_PRIZE,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.post,
        `api/prizes`,
        action.data,
        null
      );
      yield put({
        type: actions.CREATE_PRIZE_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      console.log(error);
      yield put({ type: actions.CREATE_PRIZE_FAILED });
      yield call(action.reject, error);
    }
  }
);

const fetchPrizes = takeLatest(
  actions.FETCH_PRIZES,
  function* (
    action: AnyAction
  ): Generator<Effect, void, PrizeReturnType> {
    try {
      const response = yield call(
        api.get,
        urlGenerator("api/prizes", action)
      );
      yield put({
        type: actions.FETCH_PRIZES_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_PRIZES_FAILED });
      yield call(action.reject);
    }
  }
);

const fetchPrize = takeLatest(
  actions.FETCH_PRIZE,
  function* (
    action: AnyAction
  ): Generator<Effect, void, PrizeReturnType> {
    try {
      const response = yield call(
        api.get,
        `api/prizes/${action.id}`,
        false
      );
      yield put({
        type: actions.FETCH_PRIZE_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_PRIZE_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const updatePrize = takeLatest(
  actions.UPDATE_PRIZE,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.put,
        `api/prizes/${action.data.id}`,
        action.data
      );
      yield put({
        type: actions.UPDATE_PRIZE_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error: any) {
      yield put({ type: actions.UPDATE_PRIZE_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const deletePrize = takeLatest(
  actions.DELETE_PRIZE,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.delete, `api/prizes/${action.id}`);
      yield put({
        type: actions.DELETE_PRIZE_SUCCESS,
        payload: action.id,
      });
      yield call(action.resolve, response);
    } catch (error: any) {
      yield put({ type: actions.DELETE_PRIZE_FAILED });
      yield call(action.reject, error.response.data?.errors);
    }
  }
);

const fetchPrizeRedeemedBy = takeLatest(
  actions.FETCH_PRIZE_REDEEMED_BY,
  function* (
    action: AnyAction
  ): Generator<Effect, void, PrizeReturnType> {
    try {
      const response = yield call(
        api.get,
        urlGenerator("api/redeem", action)
      );
      yield put({
        type: actions.FETCH_PRIZE_REDEEMED_BY_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_PRIZE_REDEEMED_BY_FAILED });
      yield call(action.reject);
    }
  }
);

export default function* saga() {
  yield all([
    createPrize,
    fetchPrizes,
    updatePrize,
    deletePrize,
    fetchPrize,
    fetchPrizeRedeemedBy
  ]);
}
