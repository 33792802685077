const actions = {
  FETCH_ACHIEVEMENTS: "FETCH_ACHIEVEMENTS",
  FETCH_ACHIEVEMENTS_SUCCESS: "FETCH_ACHIEVEMENTS_SUCCESS",
  FETCH_ACHIEVEMENTS_FAILED: "FETCH_ACHIEVEMENTS_FAILED",
  CREATE_ACHIEVEMENT: "CREATE_ACHIEVEMENT",
  CREATE_ACHIEVEMENT_SUCCESS: "CREATE_ACHIEVEMENT_SUCCESS",
  CREATE_ACHIEVEMENT_FAILED: "CREATE_ACHIEVEMENT_FAILED",
  UPDATE_ACHIEVEMENT: "UPDATE_ACHIEVEMENT",
  UPDATE_ACHIEVEMENT_SUCCESS: "UPDATE_ACHIEVEMENT_SUCCESS",
  UPDATE_ACHIEVEMENT_FAILED: "UPDATE_ACHIEVEMENT_FAILED",
  DELETE_ACHIEVEMENT: "DELETE_ACHIEVEMENT",
  DELETE_ACHIEVEMENT_SUCCESS: "DELETE_ACHIEVEMENT_SUCCESS",
  DELETE_ACHIEVEMENT_FAILED: "DELETE_ACHIEVEMENT_FAILED",
  FETCH_ACHIEVEMENT: "FETCH_ACHIEVEMENT",
  FETCH_ACHIEVEMENT_SUCCESS: "FETCH_ACHIEVEMENT_SUCCESS",
  FETCH_ACHIEVEMENT_FAILED: "FETCH_ACHIEVEMENT_FAILED",

  createAchievement: (
    data: Object,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.CREATE_ACHIEVEMENT,
    data,
    resolve,
    reject,
  }),

  updateAchievement: (
    data: Object,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.UPDATE_ACHIEVEMENT,
    data,
    resolve,
    reject,
  }),

  fetchAchievements: (
    // page: number,
    // pageSize: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_ACHIEVEMENTS,
    // page,
    // pageSize,
    resolve,
    reject,
  }),

  fetchAchievement: (
    // page: number,
    // pageSize: number,
    id: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_ACHIEVEMENT,
    // page,
    // pageSize,
    id,
    resolve,
    reject,
  }),

  deleteAchievement: (
    id: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.DELETE_ACHIEVEMENT,
    id,
    resolve,
    reject,
  }),
};

export default actions;
