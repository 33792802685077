import { all } from "redux-saga/effects";
import { userSaga } from "../pages/login/store";
import { evaluationPlacementsSaga } from "pages/evaluationTestRequests/store";
import { coursesSaga } from "pages/course/store";
import { instructorsSaga } from "pages/instructor/store";
import { branchSaga } from "pages/branch/store";
import { timeSlotSaga } from "pages/timeSlot/store";
import { batchSaga } from "pages/batch/store";
import { myKidsSaga } from "pages/home/ParentDashboard/store";
import { childSaga } from "pages/home/ChildDashboard/store";
import { branchAdminSaga } from "pages/home/AdminDashboard/store";
import { studentsSaga } from "pages/students/store";
import { attendanceSaga } from "pages/attendance/store";
import { changeRequestSaga } from "pages/admin/store";
import { leaveSaga } from "pages/LeaveRequests/store";
import { notificationSaga } from "pages/notifications/store";
import { conversationListSaga } from "pages/chat/store/conversationlist";
import { messageListSaga } from "pages/chat/store/message";
import { assignmentSaga } from "pages/assignment/store";
import { newEventsSaga } from "pages/News&Events/store";
import { branchAdminsSaga } from "pages/branchAdmin/store";
import { superAdminsSaga } from "pages/superadmin/store";
import { couponSaga } from "pages/coupons/store";
import { settingsSaga } from "pages/settings/store";
import { studentPaymentStatusSaga } from "pages/billing&payments/store";
import { removeChildSaga } from "pages/removeChild/store";
import { allChildSaga } from "pages/child/store";
import { branchAssociatesSaga } from "pages/branchAssociate/store";
import { logsSaga } from "pages/logs/store";
import { branchSelfServicesSaga } from "pages/branchSelfService/store";
import { fetchQuestionsSaga } from "pages/surveyform/store";
import { fetchSurveyAnswersSaga } from "pages/survey/store";
import { trialClassesSaga } from "pages/trialClassRequest/store";
import { appointmentSaga } from "pages/bookAnAppointment/store";
import { pickupPersonsSaga } from "pages/pickupPerson/store";
import { mailingAddressSaga } from "pages/enrollment/store";
import { employeeAttendanceSaga } from "pages/employeeAttendance/store";
import { pendingStudentsSaga } from "pages/pendingStudents/store";
import { userDetailsSaga } from "pages/website/store";
import { childDetailsSaga } from "pages/waitingList/store";
import { timesheetSaga } from "pages/timeSheetReport/store";
import { customerSupportAdminsSaga } from "pages/customerSupportAdmin/store";
import { customerSupportUsersSaga } from "pages/customerSupportUser/store";
import { parentAndChildAssociationSaga } from "pages/parentAndChildAssociation/store";
import { referralsSaga } from "pages/referral/store";
import { refundsSaga } from "pages/fundManagement/store";
import { creditAndDiscountsSaga } from "pages/creditAndDiscounts/store";
import { campsSaga } from "pages/camp/store";
import { pricesSaga } from "pages/priceAndPlans/store";
import { achievementSaga } from "pages/achievements/store";
import { rewardsSaga } from "pages/rewards/store";
import { prizeSaga } from "pages/prizes/store";
import { disableDatesSaga } from "pages/disableDates/store";

export default function* rootSaga() {
  yield all([
    ...userSaga,
    ...evaluationPlacementsSaga,
    ...coursesSaga,
    ...instructorsSaga,
    ...branchSaga,
    ...timeSlotSaga,
    ...batchSaga,
    ...myKidsSaga,
    ...childSaga,
    ...branchAdminSaga,
    ...studentsSaga,
    ...attendanceSaga,
    ...changeRequestSaga,
    ...leaveSaga,
    ...notificationSaga,
    ...conversationListSaga,
    ...messageListSaga,
    ...assignmentSaga,
    ...newEventsSaga,
    ...branchAdminsSaga,
    ...superAdminsSaga,
    ...couponSaga,
    ...settingsSaga,
    ...studentPaymentStatusSaga,
    ...removeChildSaga,
    ...allChildSaga,
    ...branchAssociatesSaga,
    ...logsSaga,
    ...branchSelfServicesSaga,
    ...fetchQuestionsSaga,
    ...fetchSurveyAnswersSaga,
    ...trialClassesSaga,
    ...appointmentSaga,
    ...pickupPersonsSaga,
    ...mailingAddressSaga,
    ...employeeAttendanceSaga,
    ...pendingStudentsSaga,
    ...userDetailsSaga,
    ...childDetailsSaga,
    ...timesheetSaga,
    ...customerSupportAdminsSaga,
    ...customerSupportUsersSaga,
    ...parentAndChildAssociationSaga,
    ...referralsSaga,
    ...refundsSaga,
    ...creditAndDiscountsSaga,
    ...campsSaga,
    ...pricesSaga,
    ...achievementSaga,
    ...rewardsSaga,
    ...prizeSaga,
    ...disableDatesSaga
  ]);
}
