import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

interface AchievemnetReturnType {
  [x: string]: string;
}

const createAchievement = takeLatest(
  actions.CREATE_ACHIEVEMENT,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.post,
        `api/achievement`,
        action.data,
        null
      );
      yield put({
        type: actions.CREATE_ACHIEVEMENT_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      console.log(error);
      yield put({ type: actions.CREATE_ACHIEVEMENT_FAILED });
      yield call(action.reject, error);
    }
  }
);

const fetchAchievements = takeLatest(
  actions.FETCH_ACHIEVEMENTS,
  function* (
    action: AnyAction
  ): Generator<Effect, void, AchievemnetReturnType> {
    try {
      const response = yield call(
        api.get,
        urlGenerator("api/achievement", action),
        false
      );
      yield put({
        type: actions.FETCH_ACHIEVEMENTS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_ACHIEVEMENTS_FAILED });
      yield call(action.reject);
    }
  }
);

const fetchAchievement = takeLatest(
  actions.FETCH_ACHIEVEMENT,
  function* (
    action: AnyAction
  ): Generator<Effect, void, AchievemnetReturnType> {
    try {
      const response = yield call(
        api.get,
        `api/achievement/${action.id}`,
        false
      );
      yield put({
        type: actions.FETCH_ACHIEVEMENT_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_ACHIEVEMENT_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const updateAchievement = takeLatest(
  actions.UPDATE_ACHIEVEMENT,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.put,
        `api/achievement/${action.data.id}`,
        action.data
      );
      yield put({
        type: actions.UPDATE_ACHIEVEMENT_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error: any) {
      yield put({ type: actions.UPDATE_ACHIEVEMENT_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const deleteAchievement = takeLatest(
  actions.DELETE_ACHIEVEMENT,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.delete, `api/achievement/${action.id}`);
      yield put({
        type: actions.DELETE_ACHIEVEMENT_SUCCESS,
        payload: action.id,
      });
      yield call(action.resolve, response);
    } catch (error: any) {
      yield put({ type: actions.DELETE_ACHIEVEMENT_FAILED });
      yield call(action.reject, error.response.data?.errors);
    }
  }
);

export default function* saga() {
  yield all([
    createAchievement,
    fetchAchievements,
    updateAchievement,
    deleteAchievement,
    fetchAchievement,
  ]);
}
