import DisableDatesSaga from "./sagas";
import DisableDatesReducer from "./reducers";
import disableDatesActions from "./actions";

export const disableDatesReducer = {
    disableDatesReducer: DisableDatesReducer,
};

export const disableDatesSaga = [DisableDatesSaga()];

export { disableDatesActions };