import AchievementReducer from "./reducers";
import AchievementSaga from "./sagas";
import achievementActions from "./actions";

export const achievementReducer = {
  achievementReducer: AchievementReducer,
};

export const achievementSaga = [AchievementSaga()];

export { achievementActions };